/**
 * Arquivo para ser centralizado IDS dos clientes Beta Testers das rotas do sistema
 * @type {*[]}
 */

/**
 * Lista de estabelecimentos que estarão realizando o teste do Delivery Vip
 * @type {number[]}
 */
export const TESTERS_DELIVERY_VIP = [31, 2276, 3879, 397];

/**
 * Lista de estabelecimentos que estarão realizando o teste do Delivery Vip
 * @type {number[]}
 */
export const TESTERS_CARDAPIO_WEB = [31, 2276, 3879, 397, 3114, 3116, 2952, 1756];

/**
 * Lista de estabelecimentos que estarão realizando o teste do Jota Já
 * @type {number[]}
 */
export const TESTERS_JOTA_JA = [31, 2276, 3879];

/**
 * Lista de estabelecimentos que estarão realizando o teste em relação a IA conversacional
 * @type {number[]}
 */
export const TESTERS_IA_CONVERSACIONAL = [31, 525, 1252, 1725, 1756, 2211, 2276, 2413, 2481, 2505, 2574, 2641, 2757, 2757, 2758, 2758, 2764, 3046, 3075, 3076, 3111, 3141, 3348, 3443, 3464, 3464, 3465, 3474, 3512, 3561, 3579, 3593, 3598, 3599, 3620, 3645, 3646, 3647, 3648, 3703, 3820, 3821, 3822, 3852, 3855, 3866, 3867, 3879, 3924, 3941, 3944, 3945, 3956, 3965, 3966, 3984, 4002, 4016, 4020, 4027, 4028, 4033, 4055, 4058, 4061, 4062, 4069, 4078, 4084, 4094, 4096, 4097, 4100, 4104, 4107, 4110, 4124, 4130, 4131, 4134, 4147, 4148, 4149];

/**
 * Lista de estabelecimentos que estarão realizando o teste em relação a integração com a plataforma do Fala ae
 * @type {number[]}
 */
export const TESTERS_FALA_AE = [31, 2276, 3879];

/**
 * Lista de estabelecimentos que estarão realizando o teste do Anota Ai
 * @type {number[]}
 */
export const TESTERS_ANOTA_AI = [31, 2276, 3879];
